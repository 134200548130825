.sell_and_buy_divide{
    padding-top: 15px;
    margin-bottom: 15px;
}

.section_buy_or_sell{
   display: flex;
   flex-direction: column;
   padding:  15px 15px;
   margin-top: 5px;
   background-color:  rgb(241, 241, 241);
   border-radius: 5px;   
   &_title{
    text-align: center;
    width: 120px;
    padding: 1px 3px;
    color: white;
    border-radius: 5px;
    font-weight: 500;
    }
    * {  
      &::-webkit-scrollbar-thumb {
        background-color: #fff !important; 
      }
    
      &::-webkit-scrollbar-track {
        background-color: rgb(204, 204, 204) !important; 
      }
    }
   //background-color: rgba(255, 0, 0, 0.301);
}

.l{
  color: rgb(173, 173, 173)
}




