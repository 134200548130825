:root {
    --color-1: #56B7F4;
    --color-2: #FFFC00;
    --color-3: #42A811;
    --color-4: #E619F8;
    --color-5: #000000;
    --color-6: #FFFFFF;
    --color-7: #FF8A00;
    --color-8: #999999;
    --color-9: #1F0EEB;
    --color-10: #6F12FF;
    --color-11: #FD0202;
    --color-12: #3DFFFF;
    --color-13: #228B22; /* Marrón */
    --color-14: #FF00FF; /* Magenta brillante */
    --color-15: #8B4513; /* Marrón oscuro */
    --color-16: #FFE4B5; /* Beige claro */
    --color-17: #008080; /* Turquesa oscuro */
    --color-18: #800000; /* Rojo marrón */
    --color-19: #F5DEB3; /* Trigo */
    --color-20: #FF4500; /* Rojo anaranjado */
    --color-21: #4682B4; /* Azul acero */
    --color-22: #4B0082; /* Índigo */
    --color-23: #9400D3; /* Púrpura oscuro */
    --color-24: #FFDAB9; /* Melocotón */
    --color-25: #20B2AA; /* Turquesa claro */
    --color-26: #B22222; /* Rojo fuego */
    --color-27: #6A5ACD; /* Azul pizarra */
    --color-28: #A0522D; /* Verde bosque */
    --color-29: #FF69B4; /* Rosa fuerte */
    --color-30: #FAF0E6; /* Lino */

    --text-color-1: #1E1E1E; /* Gris oscuro */
    --text-color-2: #333333; /* Gris carbón */
    --text-color-3: #4B0082; /* Índigo */
    --text-color-4: #800000; /* Rojo marrón */
    --text-color-5: #2F4F4F; /* Verde grisáceo oscuro */
    --text-color-6: #8B4513; /* Marrón oscuro */
    --text-color-7: #483D8B; /* Azul pizarra oscuro */
    --text-color-8: #2E2E2E; /* Gris muy oscuro */
    --text-color-9: #000080; /* Azul marino */
    --text-color-10: #222222; /* Negro atenuado */

    --text-color-1: #FF0000; /* Rojo intenso */
    --text-color-2: #FF4500; /* Naranja fuerte */
    --text-color-3: #FF1493; /* Rosa neón */
    --text-color-4: #8B00FF; /* Violeta eléctrico */
    --text-color-5: #0000FF; /* Azul puro */
    --text-color-6: #0096FF; /* Azul neón */
    --text-color-7: #00FF00; /* Verde fosforescente */
    --text-color-8: #FFD700; /* Amarillo brillante */
    --text-color-9: #00FFFF; /* Cian neón */
    --text-color-10: #FF00FF; /* Magenta fuerte */
}

.global{
    &_title_section{
        display: flex; 
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50px;
        border-bottom: 2px solid  #655fca;
        //background: linear-gradient( #1B2138, #655fca);
        border-radius: 5px ;
        padding: 10px 10px 10px 20px;
        h3{
            color: white;
            padding: 3px 0px  0px;
            margin: 0px;
            font-size: 15px;
        } 
    }
    
    &_button_box{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 10px;
        padding-left: 30px;
    }
}

.ant-table-placeholder{
    background-color: #1B2138 !important;
    .ant-empty-description{
        color: white;
    }
}
.ant-pagination-item{
    a{
        color: white !important;
    }
}
.ant-pagination-next{
    button{
        color: white !important;
    }
}
.ant-pagination-prev{
    button{
        color: white !important;
    }
}

.ant-menu-dark.ant-menu-root.ant-menu-inline{
    overflow-y: auto;
    /* Para navegadores que no sean WebKit (como Firefox) */
    scrollbar-width:thin; /* Ancho del scrollbar */
    scrollbar-color: #6b6f92 #121832 !important; /* Color del scrollbar y del fondo del track */   
}

/* Estilo del scroll para WebKit (Chrome, Safari) */
.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar {
    width: 8px; /* Ancho del scrollbar */
}
  
.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar-track {
    background-color: #121832; /* Color del fondo del track */
}

.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar-thumb {
    background-color: #6b6f92 !important; /* Color del scrollbar */
    border-radius: 4px; /* Redondear las esquinas */
}



.ant-menu-item-selected{
    background: linear-gradient(to right, #37bde0, #655fca) !important//  #0072ce;
}


.ant-btn-primary{
    background-color:  #37bde0 !important;
    //border: none !important;
    //box-shadow: none !important;
    transition: transform 0.2s;
    &:hover{
        background-color:  #71e3ff !important;
    }    
    &:active{
        transform: scale(0.95);
    }
}

.ant-btn-default{
    border-color: #655fca;
    color: #655fca;
    transition: transform 0.2s;
    &:hover{
        border-color: #81a8fd !important;
        color: #81a8fd !important;
    }
    &:active{
        transform: scale(0.95);
    }
}
//boton gosh
.ant-radio-button-wrapper{
    border: 1px solid #655fca;
    color: #655fca;
    transition: transform 0.2s;
    &:hover{
        border-color: #81a8fd !important;
        color: #81a8fd !important;
    }
    &:active{
        transform: scale(0.95);
    }
}
.ant-radio-button-wrapper:first-child{
    border-inline-start: 1px solid #655fca;;
}

.ant-pagination-item-active{
    background-color: transparent !important;
    border-color:  #37bde0 !important;
    a{
        color:  #37bde0 !important
    }
}


//colores para cell fixes
.ant-table-tbody{
    .ant-table-cell-fix-left{
        color: white;
        background-color: #242b49;
    }
    .ant-table-cell-fix-right{
        color: white;
        background-color: #242b49;
    }
}


.ant-table-row.ant-table-row-level-0{
    color: rgb(173, 173, 173);
    &:hover{
        color: black !important
    }
    background-color: #1B2138;
}

.ant-table-cell-row-hover{
    //color: #30cb85;// white !important;
    font-weight: 200 !important;
    background-color: hsla(0, 0%, 100%, 0.9)!important;
    //border-bottom: 3px solid #37bde0 !important;

}

.ant-table-wrapper {
    td.ant-table-column-sort {
        background: transparent; /* Elimina el fondo blanco del sort activo */
    }

    .ant-table-column-sorter-down.active,
    .ant-table-column-sorter-up.active,
    .ant-table-filter-trigger.active {
        color: #37bde0 !important; /* Aplica el mismo color a los elementos activos */
    }
}

.ant-checkbox-checked .ant-checkbox-inner {//chekbox de filtros
    background-color: #37bde0;
    border-color: #37bde0;
}

.ant-btn-link{ //span reset de filtros
    color: #37bde0;
}


h2{    
    font-family: "Josefin Sans", sans-serif !important;
    font-optical-sizing: auto !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

h1, h4, h3{
    font-family: "Josefin Sans", sans-serif !important;
    font-optical-sizing: auto !important;
    font-weight: 400 !important;
    font-style: normal !important;
    color: white;
}

.cursor-pointer{
    cursor: pointer;
}


.class_is_deleting_operation{
    color: rgba(128, 128, 128, 0.671),// rgba(255, 0, 0, 0.808)
} 
.class_false{
    color:#c4c4c4
}

.class_deleted{
    color:  rgba(128, 128, 128, 0.671)
}

.text-right {
    text-align: right !important;
  }

.text-center {
    text-align: center !important;
}

.text-orange{
    color:orange
}

.text-green{
    color: #30cb85
}

//ESTA CLASE SE APLICA AL PADRE QUE CONTIENE LOS COMPONENTES RENDER PARA LAS TABLAS, PORQUE SI USO EL GLOBAL NO APLICA LOS CAMBIOS
.hover_row{
    .ant-table-cell-row-hover{
        color: rgb(31, 31, 31) !important;// white !important;
        font-weight: 200;    
    }
    .ant-table-row:hover & {
        color: rgb(31, 31, 31) !important;
        font-weight: 200;
      }
}

.class_balance{ //COLOR DE LOS SALDOS
    color: #37bde0
}

* {
    &::-webkit-scrollbar {
      width: 8px; // Más angosto
      height: 8px; // Para scroll horizontal
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #4a4a4a; // Gris oscuro
      border-radius: 10px; // Más redondeado
      border: 2px solid transparent; // Espacio interno
      background-clip: padding-box; // Mantener el borde visible
    }
  
    &::-webkit-scrollbar-track {
      background-color: #121832; // Gris oscuro para la pista
      border-radius: 10px; // También redondeado
    }
  }

  .ant-table-thead > tr > th {
    font-size: 11px; /* Ajusta el tamaño del texto */
    padding: 6px; /* Ajusta el espacio interno */
    line-height: 1; /* Reduce la altura de línea */
    border-right: 1px solid rgba(128, 128, 128, 0.445); // Cambia el grosor y color del borde
    border-top: 1px solid gray;
    //background-color: #f5f5f5; 
    text-align: center;  
  }

  //visualizador de moneda en input Inputnumber
  .ant-input-number-group .ant-input-number-group-addon{
    background-color: white;
  }

  .ant-table-wrapper .ant-table{
    font-size: 12px !important;
  }

  .center_element{
    display: flex;
    align-items: center;
    justify-content: center;
  }

