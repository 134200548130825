.allCheckout_section{
    &_container{
        overflow-y: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        } 
            
    } 
    &_btn{
        margin-right: 5px;                      
    }
    &_icon{
        .anticon svg {
            color: #37bde0 !important;
            &:hover{
                color: #71e3ff !important     
            }
        }
        
    }
    .ant-table-cell{
        padding: 7px !important;
        
    } 
    // .ant-table-cell{
    //     padding: 10px !important;
        
    // } 
    .ant-table-thead > tr > th {
        font-size: 11px; /* Ajusta el tamaño del texto */
        padding: 6px; /* Ajusta el espacio interno */
        line-height: 1; /* Reduce la altura de línea */
        border-right: 1px solid rgba(128, 128, 128, 0.445); // Cambia el grosor y color del borde
        border-top: 1px solid gray;
        //background-color: #f5f5f5; 
        text-align: center;  
    }        
}

.cell_window{
    color: yellow;
   // color: #dfdf16;
 }

 .cell_deleted td {
    color: rgb(114, 114, 114) !important;
    //opacity: 0.6 !important;
}

.background_color_cell_checkout{
    background-color: #242B49;
}

// .divide{
//     border-left: 1px solid rgb(66, 66, 66);
// }


//  .ant-table-cell-row-hover{
//         color: #30cb85 !important;// white !important;
//         font-weight: 500;    
//     }